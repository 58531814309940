<template>
  <div class="email-app-details bg-white">

    <!-- Share Header -->
    <div class="email-detail-header">
      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
            size="20"
            class="align-bottom"
            @click="$emit('close-form')"
          />
        </span>
        <h4 class="email-subject mb-0">
          <b-row>
            <b-col md="12">
              {{ $t('Nova proposta') }}
            </b-col>
          </b-row>
        </h4>
      </div>
    </div>

    <vue-perfect-scrollbar
      ref="scrollForm"
      class="email-scroll-area scroll-area pr-0 pl-0"
      :settings="configSettingsScroll"
    >
      <b-container
        fluid
        class="p-2"
      >

        <b-row class="mt-2">
          <b-col md="12">
            <h4>
              {{ $t('Visita') }}
            </h4>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group
              :label="$t('ID')"
            >
              <p class="mb-0">
                <strong>{{ visit.sw070s20 }}</strong>
              </p>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group
              :label="$t('Data')"
            >
              <p class="mb-0">
                <strong>{{ visit.sw070s10 }}</strong>
              </p>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col md="12">
            <h4>
              {{ $t('Angariação') }}
            </h4>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            md="12"
            class="mb-1"
          >
            <div class="d-flex justify-content-start">
              <b-img
                rounded
                :src="typeof listingImages !== 'undefined' && typeof listingImages[0] !== 'undefined' ? listingImages[0] : imagePreview"
                height="100"
              />
              <div class="d-flex flex-column ml-1">
                <div class="mb-1">
                  <h5 class="mb-0">
                    {{ listing.sw004v02 }} {{ listing.sw005v02 }}
                  </h5>
                  <h6
                    v-if="typeof listing.currencyL !== 'undefined' && listing.currencyL !== ''"
                    class="mb-0"
                  >
                    {{ listing.currencyL }} {{ listing.sw012v14v69 }}
                  </h6>
                  <h6
                    v-if="typeof listing.currencyR !== 'undefined' && listing.currencyR !== ''"
                    class="mb-0"
                  >
                    {{ formatPrice(listing.sw012v14v69) }} {{ listing.currencyR }}
                  </h6>
                  <p class="card-text mb-0">
                    {{ listing.sw012v02 }}
                  </p>
                  <p
                    v-if="![2].includes(Number(visit.sw070s29))"
                    class="card-text mb-0"
                  >
                    {{ listing.sw016v02 }} > <strong>{{ listing.sw015v02 }}</strong>
                  </p>
                  <p
                    v-if="![2].includes(Number(visit.sw070s29))"
                    class="card-text mb-0"
                  >
                    <strong>{{ listing.sw012v263 }}</strong>
                  </p>
                  <p
                    v-if="listing.codigoPostal !== null && listing.codigoPostal !== ''"
                    class="card-text mb-0"
                  >
                    {{ listing.codigoPostal }}
                  </p>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row
          v-if="![2].includes(Number(visit.sw070s29))"
          class="mt-2"
        >
          <b-col md="12">
            <h4>
              {{ $t('Angariador') }}
            </h4>
          </b-col>
        </b-row>

        <b-row
          v-else
          class="mt-2"
        >
          <b-col md="12">
            <h4>
              {{ $t('Agente externo') }}
            </h4>
          </b-col>
        </b-row>

        <b-row
          v-if="![2].includes(Number(visit.sw070s29))"
        >
          <div class="d-flex justify-content-start ml-1">
            <b-avatar
              :src="user.imagem"
              size="50px"
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h5 class="mb-0">
                  {{ user.nomeUser }}
                </h5>
                <h6
                  v-if="user.nameHUB !== ''"
                  class="card-text mb-0"
                >
                  {{ user.nameHUB }}
                </h6>
                <span class="card-text">{{ user.contactoUser }}</span>
              </div>
            </div>
          </div>
        </b-row>

        <b-row
          v-else
        >
          <div class="d-flex justify-content-start ml-1">
            <b-avatar
              :src="agentExternal.sw025s121"
              size="50px"
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h5 class="mb-0">
                  {{ agentExternal.nome }}
                </h5>
                <h6
                  v-if="agentExternal.rede_imobiliaria !== ''"
                  class="card-text mb-0"
                >
                  {{ agentExternal.rede_imobiliaria }} {{ agentExternal.agencia }}
                </h6>
                <span class="card-text">{{ agentExternal.sw025s05 }}</span>
              </div>
            </div>
          </div>
        </b-row>

        <b-row
          v-if="visit.nomelead !== null && visit.nomelead !== ''"
          class="mt-2"
        >
          <b-col md="12">
            <h4>
              {{ $tc('Lead', 1) }}
            </h4>
          </b-col>
        </b-row>

        <b-row
          v-if="visit.nomelead !== null && visit.nomelead !== ''"
        >
          <b-col
            md="12"
            class="mb-1"
          >
            <div class="d-flex justify-content-start">
              <b-avatar
                size="50px"
              />
              <div class="d-flex flex-column ml-1">
                <div class="mb-1">
                  <h5 class="mb-0">
                    {{ visit.nomelead }}
                  </h5>
                  <span
                    v-if="Number(actionLead.sw025_actionView) === 1"
                    class="card-text"
                  >{{ visit.sw025s05 }}</span>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-form
          ref="formSaveProposal"
          @submit.prevent
        >
          <validation-observer
            #default="{ }"
            ref="formSaveProposalValidator"
          >
            <b-row class="mt-2">
              <b-col md="12">
                <h4>
                  {{ $t('Proposta de negócio') }}
                </h4>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('Tipo de negócio')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <v-select
                      v-model="form_data.sw131s03"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="typeBusiness"
                      append-to-body
                      :calculate-position="withPopper"
                      :disabled="true"
                      label="sw007s02"
                      item-text="sw007s02"
                      item-value="sw007s01"
                    >
                      <template #option="{ sw007s02 }">
                        {{ sw007s02 }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('Valor')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-input-group
                      :prepend="symbolMoney"
                      append=""
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        v-model.lazy="form_data.sw131s04"
                        v-currency="configMoney"
                        placeholder=""
                        autocomplete="off"
                        :class="errors.length > 0 ? 'is-invalid':null"
                        @input="updateField($event, true)"
                      />
                    </b-input-group>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('Data da proposta')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <date-picker
                      v-model="form_data.sw131s05"
                      value-type="format"
                      :input-class="{'form-control' : true, 'is-invalid': errors.length > 0 }"
                      type="date"
                      :editable="false"
                      :clearable="true"
                      :lang="langCalendar"
                      :append-to-body="true"
                      prefix-class="sw"
                      :disabled-date="disabledMaxDate"
                    >
                      <template #icon-clear>
                        <v-select-deselect />
                      </template>
                    </date-picker>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col md="12">
                <h4>
                  {{ $t('Entrega no contrato de promessa de compra e venda') }}
                </h4>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <b-form-group
                  :label="$t('Valor')"
                >
                  <b-input-group
                    :prepend="symbolMoney"
                    append=""
                    class="input-group-merge"
                  >
                    <b-form-input
                      v-model.lazy="form_data.sw131s06"
                      v-currency="configMoney"
                      placeholder=""
                      autocomplete="off"
                      @input="updateField"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <b-form-group
                  :label="$t('Data')"
                >
                  <date-picker
                    v-model="form_data.sw131s07"
                    value-type="format"
                    input-class="form-control"
                    type="date"
                    :editable="false"
                    :clearable="true"
                    :lang="langCalendar"
                    :append-to-body="true"
                    prefix-class="sw"
                    :disabled-date="disabledMaxDateEntrega"
                  >
                    <template #icon-clear>
                      <v-select-deselect />
                    </template>
                  </date-picker>
                </b-form-group>
              </b-col>
            </b-row>

            <div
              v-for="(row, index) in aReforcos"
              :key="`reforco-${index}`"
            >
              <b-row class="mt-2">
                <b-col md="12">
                  <h4>
                    {{ $t('Reforço de sinal') }} - {{ index + 1 }}
                  </h4>
                </b-col>
              </b-row>

              <b-row :key="`reforco-valor-${index}`">
                <b-col md="6">
                  <b-form-group
                    :label="$t('Valor')"
                  >
                    <b-input-group
                      :prepend="symbolMoney"
                      append=""
                      class="input-group-merge"
                    >
                      <b-form-input
                        v-model.lazy="row.valor"
                        v-currency="configMoney"
                        placeholder=""
                        autocomplete="off"
                        @input="updateField"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row :key="`reforco-data-${index}`">
                <b-col md="6">
                  <b-form-group
                    :label="$t('Data')"
                  >
                    <date-picker
                      v-model="row.data"
                      value-type="format"
                      input-class="form-control"
                      type="date"
                      :editable="false"
                      :clearable="true"
                      :lang="langCalendar"
                      :append-to-body="true"
                      prefix-class="sw"
                      :disabled-date="disabledMaxDateReforco"
                      @input="changeData"
                    >
                      <template #icon-clear>
                        <v-select-deselect />
                      </template>
                    </date-picker>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="aReforcos.length > 0 && (index + 1) !== aReforcos.length">
                <b-col md="2">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger"
                    class="btn-icon mb-1"
                    @click="removeReforco(index)"
                  >
                    <span class="align-middle">{{ $t('Eliminar') }}</span>
                  </b-button>
                </b-col>
              </b-row>
            </div>

            <b-row>
              <b-col md="2">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="btn-icon"
                  @click="addReforco"
                >
                  <span class="align-middle">{{ $t('Adicionar reforço') }}</span>
                </b-button>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col md="6">
                <h4>
                  {{ $t('Valor a entregar no ato da escritura') }}
                </h4>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('Valor')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-input-group
                      :prepend="symbolMoney"
                      append=""
                      class="input-group-merge"
                      :disabled="true"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        v-model.lazy="form_data.sw131s26"
                        v-currency="configMoney"
                        placeholder=""
                        autocomplete="off"
                        :disabled="true"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      />
                    </b-input-group>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('Data')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <date-picker
                      v-model="form_data.sw131s28"
                      value-type="format"
                      :input-class="{'form-control' : true, 'is-invalid': errors.length > 0 }"
                      type="date"
                      :editable="false"
                      :clearable="true"
                      :lang="langCalendar"
                      :append-to-body="true"
                      prefix-class="sw"
                      :disabled-date="disabledMaxDateEscritura"
                    >
                      <template #icon-clear>
                        <v-select-deselect />
                      </template>
                    </date-picker>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row v-if="configApp.bUseCaucoesPVP === true">
              <b-col md="6">
                <b-form-group
                  :label="$t('Caução')"
                >
                  <b-input-group
                    :prepend="symbolMoney"
                    append=""
                    class="input-group-merge"
                  >
                    <b-form-input
                      v-model.lazy="form_data.sw131s36"
                      v-currency="configMoney"
                      placeholder=""
                      autocomplete="off"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <b-form-group
                  :label="$t('Condições complementares')"
                >
                  <b-form-textarea
                    v-model="form_data.sw131s08"
                    placeholder=""
                    rows="4"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <b-form-group
                  :label="$t('Vai recorrer a financiamento?')"
                >
                  <div class="d-flex justify-content-start">
                    <b-form-checkbox
                      v-model="vlCheckbox"
                      value="1"
                      @change="changeCheckbox"
                    >
                      {{ $t('Sim') }}
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="vlCheckbox"
                      value="0"
                      class="ml-1"
                      @change="changeCheckbox"
                    >
                      {{ $t('Não') }}
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>

            <div v-if="Number(vlCheckbox) === 1">
              <b-row>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Valor')"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-input-group
                        :prepend="symbolMoney"
                        append=""
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          v-model.lazy="form_data.sw130s08"
                          v-currency="configMoney"
                          placeholder=""
                          autocomplete="off"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        />
                      </b-input-group>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Banco')"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <v-select
                        v-model="form_data.sw130s09"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="banks"
                        append-to-body
                        :calculate-position="withPopper"
                        label="sw129s02"
                        item-text="sw129s02"
                        item-value="sw129s01"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <template #option="{ sw129s02 }">
                          {{ sw129s02 }}
                        </template>
                        <div slot="no-options">
                          {{ $t('Sem resultados') }}
                        </div>
                      </v-select>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </div>

            <b-row class="mt-2">
              <b-col md="12">
                <h4>
                  {{ $t('Anexos') }}
                </h4>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="btn-icon"
                  @click="$refs.refDocsProposal.$el.click()"
                >
                  <span class="align-middle">{{ $t('Carregar ficha de proposta digitalizada') }}</span>
                </b-button>
                <b-form-file
                  ref="refDocsProposal"
                  accept="*"
                  :hidden="true"
                  plain
                  multiple
                  @input="uploadDocRender"
                />
              </b-col>
            </b-row>

            <div
              v-for="(row, index) in aFilesProposal"
              :key="`docs-${index}`"
              class="mt-1"
            >
              <b-row>
                <b-col md="6">
                  <b-form-group
                    :label="$t('Documento')"
                  >
                    <div class="text-truncate txt-label text-primary text-break-word">
                      {{ row.name }}
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    :label="$t('Legenda')"
                  >
                    <b-form-input
                      v-model="row.legend"
                      type="text"
                      autocomplete="off"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger"
                    class="btn-icon mb-1"
                    @click="eliminarDoc(index)"
                  >
                    <span class="align-middle">{{ $t('Eliminar') }}</span>
                  </b-button>
                </b-col>
              </b-row>
            </div>

          </validation-observer>
        </b-form>

      </b-container>

      <div
        class="d-flex p-1 justify-content-center d-block d-sm-none mb-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-2"
          type="submit"
          @click.stop.prevent="$emit('save-new-proposal')"
        >
          {{ $t('Submeter') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-danger"
          @click.stop.prevent="$emit('cancel-new-proposal')"
        >
          {{ $t('Cancelar') }}
        </b-button>
      </div>

      <b-overlay
        :show="$store.getters['crm_proposals_form/loading']"
        no-wrap
      />

    </vue-perfect-scrollbar>

  </div>
</template>

<script>
import {
  BRow, BCol, BOverlay, VBTooltip, BContainer, BFormGroup, BImg, BAvatar, BForm, BInputGroup, BFormInput, BFormTextarea, BFormCheckbox, BButton, BFormFile,
} from 'bootstrap-vue'
import store from '@/store'
import '@/libs/sweet-alerts'
import { ref, onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import crmProposalsFormModule from '@store-modules/crm/proposals/form'
import { mapGetters } from 'vuex'
import { Dialog } from '@capacitor/dialog'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { showModalDeleteMessage } from '@core/utils/utils'
import { CurrencyDirective } from 'vue-currency-input'
import { configMoney, symbolMoney } from '@core-custom/utils/money'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import useCapacitor from '@core-custom/utils/capacitor'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { configSettingsScroll, formatPrice } from '@core-custom/utils/ui'
import { mixFormGeral } from './mixins'

const oDatepicker = () => Promise.all([
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ 'vue2-datepicker'),
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ '@/assets/scss/datepicker.scss'),
]).then(([DatePicker]) => DatePicker)

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BOverlay,
    BFormGroup,
    BImg,
    BAvatar,
    BForm,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    BFormFile,
    vSelect,
    vSelectDeselect: vSelect.components.Deselect,
    ValidationObserver,
    ValidationProvider,
    VuePerfectScrollbar,
    DatePicker: oDatepicker,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
    currency: CurrencyDirective,
  },
  mixins: [mixFormGeral, sizeIconsAction],
  props: {
  },
  data() {
    const now = new Date()
    const maxDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const minDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    minDate.setDate(minDate.getDate() - 2)

    return {
      configMoney,
      symbolMoney,
      maxDate,
      minDate,
      vlCheckbox: 0,
      langCalendar: {},
      aReforcos: [],
      aFilesProposal: [],
      resumeErrorUpload: [],
      formatPrice,
    }
  },
  computed: {
    ...mapGetters('crm_proposals_form', ['visit', 'listing', 'user', 'agentExternal', 'actionLead', 'typeBusiness', 'banks', 'form_data', 'configApp', 'listingImages', 'imagePreview']),
  },
  watch: {
    form_data: {
      handler(event) {
        if (typeof event.sw131s07 !== 'undefined' && event.sw131s07 !== '' && event.sw131s07 !== null) {
          const date1 = new Date(event.sw131s07)

          this.aReforcos.forEach((item, index) => {
            if (item.data !== '') {
              const dateR = new Date(item.data)
              if (date1 > dateR) {
                this.aReforcos[index].data = ''
              }
            }
          })

          if (typeof event.sw131s28 !== 'undefined' && event.sw131s28 !== '' && event.sw131s28 !== null) {
            const date2 = new Date(event.sw131s28)
            if (date1 > date2) {
              store.dispatch('crm_proposals_form/updateFieldValue', { field: 'sw131s28', value: null })
            }

            this.aReforcos.forEach(item => {
              if (item.data !== '' && this.form_data.sw131s28 !== null) {
                const dateRe = new Date(item.data)
                if (dateRe > date2) {
                  store.dispatch('crm_proposals_form/updateFieldValue', { field: 'sw131s28', value: null })
                }
              }
            })
          }
        }
      },
      deep: true,
    },
  },
  created() {
    this.aReforcos.push({ valor: '', data: '' })
  },
  beforeDestroy() {
    if (this.$refs.listviewDataScroll) {
      this.$refs.listviewDataScroll.destroy()
    }
  },
  async mounted() {
    await this.importLocale(this.$i18n.locale).then(res => {
      this.langCalendar = res.default
      // Forçei estes valores para a semana começar sempre no domingo
      this.langCalendar.formatLocale.firstDayOfWeek = 0
      this.langCalendar.formatLocale.firstWeekContainsDate = 4
    })
  },
  methods: {
    async updateField(event, flag) {
      if (typeof this.form_data.sw131s04 !== 'undefined') {
        let vlProposal = this.form_data.sw131s04 || parseFloat(0.00)
        if (flag) {
          vlProposal = event
        }

        let vlEntrega = this.form_data.sw131s06 || parseFloat(0.00)
        let vlReforcos = parseFloat(0.00)

        vlProposal = vlProposal.replace(/\./g, '').replace(',', '.')

        if (vlEntrega !== 0) {
          vlEntrega = vlEntrega.replace(/\./g, '').replace(',', '.')
        }

        this.aReforcos.forEach(item => {
          if (item.valor !== '') {
            const vlRef = item.valor.replace(/\./g, '').replace(',', '.')
            vlReforcos = parseFloat(vlReforcos) + parseFloat(vlRef)
          }
        })

        const vlAux = parseFloat(vlProposal) - parseFloat(vlEntrega) - parseFloat(vlReforcos)

        await store.dispatch('crm_proposals_form/updateFieldValue', { field: 'sw131s26', value: vlAux.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') })
      }
    },
    addReforco() {
      let bInsert = true
      this.aReforcos.forEach(item => {
        if (item.valor === '' || item.data === '') {
          bInsert = false
        }
      })

      if (bInsert) {
        this.aReforcos.push({ valor: '', data: '' })
      } else {
        this.showMsgErrorRequest({ message: this.$t('Preencha os campos de valor e data') })
      }
    },
    async removeReforco(index) {
      const actionModal = new Promise(resolve => {
        showModalDeleteMessage(resolve, `${this.$t('Pretende eliminar este reforço?')}<br>${this.$t('Esta ação é irreversível!')}`)
      })

      await actionModal.then(result => {
        if (result === 'confirm') {
          this.aReforcos.splice(index, 1)
        }
      })
    },
    changeCheckbox(event) {
      this.form_data.sw130s07 = event
    },
    changeData(date) {
      if (typeof this.form_data.sw131s28 !== 'undefined' && this.form_data.sw131s28 !== '' && this.form_data.sw131s28 !== null) {
        const date1 = new Date(date)
        const date2 = new Date(this.form_data.sw131s28)
        if (date1 > date2) {
          store.dispatch('crm_proposals_form/updateFieldValue', { field: 'sw131s28', value: null })
        }
      }

      this.aReforcos.forEach((item, index) => {
        if (typeof this.aReforcos[index + 1] !== 'undefined' && this.aReforcos[index + 1].data !== '' && this.aReforcos[index + 1].data !== null) {
          if (item.data !== '') {
            const date1 = new Date(item.data)
            const date2 = new Date(this.aReforcos[index + 1].data)
            if (date1 > date2) {
              this.aReforcos[index + 1].data = ''
            }
          } else {
            this.aReforcos[index + 1].data = ''
          }
        }
      })
    },
    async uploadDocRender(aFiles) {
      if (aFiles.length > 0) {
        try {
          this.$root.$emit('app::loading', true)
          await store.dispatch('crm_proposals_events/uploadDocs', { files: aFiles }).then(result => {
            this.resetFieldUpload()

            Object.keys(result).forEach(index => {
              if (result[index].error === false) {
                this.resumeErrorUpload.push(result[index])
              } else {
                this.aFilesProposal.push({ name: result[index].filename, legend: '' })
              }
            })

            let container = this.$refs.scrollForm.$el.scrollTop
            container = Number(container + document.body.scrollHeight)

            this.$nextTick(() => {
              this.$refs.scrollForm.$el.scrollTop = container
            })
          }).catch(error => {
            this.resetFieldUpload()
            this.showMsgErrorRequest(error)
          })
        } catch (err) {
          this.resetFieldUpload()
        }
      }
    },
    resetFieldUpload() {
      this.$root.$emit('app::loading', false)
      this.resumeErrorUpload = []
      this.$refs.refDocsProposal.reset()
    },
    async showMsgErrorRequest(error) {
      if (useCapacitor.isNative() === true) {
        Dialog.alert({
          title: this.$t('Alerta'),
          message: error.message,
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('Alerta'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      }
    },
    async showMsgSuccessRequest(info) {
      if (info.message) {
        await this.$swal({
          title: '',
          text: info.message,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
      }
    },
    async importLocale(lang) {
      return import(`vue2-datepicker/locale/${lang}`)
    },
    disabledMaxDate(date) {
      return (date > this.maxDate || date < this.minDate)
    },
    disabledMaxDateEntrega(date) {
      return date < this.maxDate
    },
    disabledMaxDateReforco(date) {
      if (typeof this.form_data.sw131s07 !== 'undefined' && this.form_data.sw131s07 !== null && this.form_data.sw131s07 !== '') {
        let maxDateEntrega = new Date(this.form_data.sw131s07)

        this.aReforcos.forEach((item, index) => {
          if (index === 0) {
            maxDateEntrega = new Date(this.form_data.sw131s07)
          } else if (this.aReforcos[index - 1].data !== '' && this.aReforcos[index - 1].data !== null) {
            maxDateEntrega = new Date(this.aReforcos[index - 1].data)
          }
        })

        return date < maxDateEntrega
      }

      let dateEntrega = this.maxDate
      this.aReforcos.forEach((item, index) => {
        if (index === 0) {
          dateEntrega = this.maxDate
        } else if (this.aReforcos[index - 1].data !== '' && this.aReforcos[index - 1].data !== null) {
          dateEntrega = new Date(this.aReforcos[index - 1].data)
        }
      })

      return date < dateEntrega
    },
    disabledMaxDateEscritura(date) {
      if (typeof this.form_data.sw131s07 !== 'undefined' && this.form_data.sw131s07 !== null && this.form_data.sw131s07 !== '') {
        let maxDateEscritura = new Date(this.form_data.sw131s07)

        this.aReforcos.forEach(item => {
          if (item.data !== '') {
            maxDateEscritura = new Date(item.data)
          }
        })

        return date < maxDateEscritura
      }

      const now = new Date()
      let maxDateEscritura = new Date(now.getFullYear(), now.getMonth(), now.getDate())

      this.aReforcos.forEach(item => {
        if (item.data !== '') {
          maxDateEscritura = new Date(item.data)
        }
      })

      return date < maxDateEscritura
    },
    async eliminarDoc(index) {
      const actionModal = new Promise(resolve => {
        showModalDeleteMessage(resolve, `${this.$t('Pretende eliminar este documento?')}<br>${this.$t('Esta ação é irreversível!')}`)
      })

      await actionModal.then(result => {
        if (result === 'confirm') {
          this.aFilesProposal.splice(index, 1)
        }
      })
    },
  },
  setup() {
    const CRM_PROPOSALS_MODULE_NAME_FORM = 'crm_proposals_form'
    const refDocsProposal = ref(null)

    if (!store.hasModule(CRM_PROPOSALS_MODULE_NAME_FORM)) {
      store.registerModule(CRM_PROPOSALS_MODULE_NAME_FORM, crmProposalsFormModule)

      onUnmounted(() => {
        if (store.hasModule(CRM_PROPOSALS_MODULE_NAME_FORM)) store.unregisterModule(CRM_PROPOSALS_MODULE_NAME_FORM)
      })
    }
    return {
      refDocsProposal,
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/base/bootstrap-extended/include';
@import '@core/scss/base/components/include';

.email-application .email-app-details {
  width: 100%!important;
  z-index: 3!important;
}

.email-app-list.space-bottom{
  padding-bottom: 2.8rem;

  @include media-breakpoint-down(xs) {
    padding-bottom: 3rem;
  }
}

.email-app-details .email-scroll-area {
  height: calc( 100% - calc(4.9rem) )!important;
}

.capacitor{
  .email-scroll-area ::v-deep{
    .email-media-list li {
      animation:none!important;
      animation-fill-mode:none!important;
    }
  }
}

</style>
